import React from "react"
import {Formik} from 'formik';
import {string, object, bool, setLocale} from 'yup';
import {Form, Col, Button} from "react-bootstrap"
import { Link } from 'gatsby'

setLocale({
  mixed: {
    required: 'Заполните поле',
  },
});

const schema = object({
  name: string().required(),
  phone: string().required(),
  terms: bool().required(),
  email: string().email('Неверный формат E-mail')
});

function ContactForm({hidden, onSubmit, style, submitText}) {
  return (
    <div hidden={hidden} style={style}>
      <Formik
        validationSchema={schema}
        onSubmit={values => onSubmit(values)}
        initialValues={{
          name: '',
          phone: '',
          email: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormik01">
                  <Form.Label>Ваше имя</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationFormik02">
                  <Form.Label>Телефон</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationFormik02">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

              </Form.Row>
              <Form.Group>
                <Form.Check
                  required
                  name="terms"
                  label={<span>Согласен с <Link to="/privacyPolicy/">условиями сайта</Link></span>}
                  onChange={handleChange}
                  isInvalid={!!errors.terms}
                  feedback={errors.terms}
                  id="validationFormik0"
                  value={true}
                  style={{fontSize: "14px"}}
                />
              </Form.Group>
              <div className="text-center my-4">
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="m-auto"
                >
                  {`${submitText}`}
                </Button>
              </div>
            </Form>
          )}
      </Formik>
    </div>
  );
}

export default ContactForm