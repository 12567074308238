/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
import Header from "./header"
import {Link} from "gatsby"

const Layout = ({children, location, firstScreen}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          contacts {
            phones {
              number
              type
            }
            address
            city
            coordinates
          }
          menu {
            label
            url
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 150, height: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          logo={data.logo.childImageSharp.fixed}
          location={location}
          menu={data.site.siteMetadata.menu}
          contacts={data.site.siteMetadata.contacts}
          description={data.site.siteMetadata.description}
        />
        {firstScreen}
      <main style={{minHeight: "75vh"}}>{children}</main>
      <footer
        style={{
          backgroundColor: "rgba(0,0,0,0.8)"
        }}
        className="text-white p-4"
      >
        <Container>
          <Row className="justify-content-between">
            <Col>
              © {new Date().getFullYear()}, 
                {` `}
              <Link to="/">Алмет, сдать лом</Link>
              {`, `}
              <Link to="/privacyPolicy/">Политика конфиденциальности </Link>
            </Col>
            <Col className="text-right">
            
            {` `}
            Создание сайта <a href="https://plusmarketing.ru" className="px-2" target="_blank">plusmarketing.ru</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
