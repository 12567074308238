import React from "react"
import {FaPhone, FaWhatsapp} from 'react-icons/fa';

const trim = (s) => s.replace(/[-\(\)]/g, '')

const WhatsApp = ({phone, className}) => (
  <a
    href={`https://wa.me/${trim(phone.number).replace(/8/, '7')}`}
    className={className}
    onClick={() => {
      window.ym(41154959, 'reachGoal', phone.reachGoal);
      return true;
    }
    }
  >
    <FaWhatsapp
      style={{
        fontSize: "0.8rem",
        paddingRight: "0.1rem",
        paddingBottom: "0.1rem",
        color: "#0d0"
      }}
    />
    {phone.number}
  </a>
)

const Phone = ({phone, className}) => (
  <a
    href={`tel:${trim(phone.number)}`}
    className={className}
    onClick={() => {window.ym(41154959, 'reachGoal', phone.reachGoal); return true;}}
  >
    <FaPhone
      style={{
        fontSize: "0.8rem",
        paddingRight: "0.1rem",
        paddingBottom: "0.2rem"
      }}
    />
    {phone.number}
  </a>
)

export default ({phones, className}) => {
  return phones.map((phone, index) => {
    if (phone.type === 'whatsapp') {
      return <WhatsApp phone={phone} className={className} key={index} />
    } else
      if (phone.type === 'phone') {
        return <Phone phone={phone} className={className} key={index} />
      }
  })
}