import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "./navbar"
import Img from "gatsby-image"
import {Container, Row, Col} from "react-bootstrap"
import ContactButton from "./contactButton"
import {FaEnvelope} from 'react-icons/fa';

import Phones from "./phones"

const Header = ({
  logo,
  location,
  menu,
  contacts,
}) => (
    <header
      style={{
        background: `rgb(45, 87, 117, 1)`,
        backgroundImage: "linear-gradient(rgb(45, 87, 117), rgb(25, 25, 25))"
      }}
    >
      <Container className="text-white">
        <Row>
          <Col lg="4" className="pt-3 py-lg-4">
            <Row>
              <Col>
                <Link to="/" className="float-left mr-2 my-auto">
                  <Img fixed={logo} className="mb-2" />
                </Link>

                <ContactButton
                  className="btn btn-info d-block d-md-none my-4 mx-1"
                  title="Форма обратной связи"
                  location={location}
                >
                  <FaEnvelope
                    width="30"
                    height="30"
                    style={{
                      fontSize: "0.9rem",
                      paddingRight: "0.3rem",
                      paddingBottom: "0.2rem"
                    }}
                  />
                  <span>Написать нам</span>
                </ContactButton>

              </Col>
              <Col className="pt-2">
                <p style={{
                  fontSize: "0.8rem",
                  opacity: "0.7"
                }}>Заготовка, хранение, переработка и реализация лома черных и цветных металлов</p>
              </Col>
            </Row>
          </Col>
          <Col lg="8" className="p-3">
            <Row>
              {contacts.map((item, index) => (
                <Col className="px-3 pb-2 pb-sm-0 pt-lg-2" xs="6" lg="3" key={index}>
                  <Row>
                    <Col lg="12">
                      <Link
                        href={`tel:${item.phone}`}
                        style={{
                          color: "#f4f4f4",
                          fontSize: "0.8rem"
                        }}
                        to="/kontakty/"
                      >
                        <div style={{fontSize: "1.1rem", color: "#ffb94d"}}>{item.city}</div>
                        <div style={{opacity: "0.8"}}>{item.address}</div>
                      </Link>
                    </Col>
                    <Col lg="12">
                      <p className="m-0 p-0" key={index}>
                        <Phones phones={item.phones} className="btn text-white p-0 f-8" />
                      </p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>

        </Row>
      </Container>
      <Navbar
        logo={logo}
        location={location}
        menu={menu}
      />
    </header>
  )

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
